import React, { useEffect } from "react";
import lottie from "lottie-web";

const Animation = ({ animationData, id, className, ...props }) => {
    useEffect(() => {
        lottie.loadAnimation({
            container: document.querySelector(`#${id}`),
            animationData: animationData,
            autoplay: true,
        });
    }, []);

    return <div id={id} className={`w-64 h-64 ${className || ""}`} {...props} />;
};

export default Animation;
