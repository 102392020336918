import React from "react";
import uploading from "../Animations/uploading.json";
import Animation from "./Animation";

const NotFound = () => (
    <>
        <Animation animationData={uploading} id="uploading-animation-container" className="self-center" />
        <p className="text-lg text-gray-600 text-center select-none">الرجاء التأكد من رمز القائمة او الإنتظار حتى إنتهاء رفع البيانات</p>
    </>
);
export default NotFound;
