import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loading from "./Components/Loading";
import NotFound from "./Components/NotFound";
import Invoice from "./Components/Invoice";

const API_ROOT = !!process.env.REACT_APP_API_ROOT ? `${process.env.REACT_APP_API_ROOT}/api/v1` : "/api/v1";

const InvoiceView = () => {
    let { id } = useParams();

    const [invoice, setInvoice] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        axios
            .get(`${API_ROOT}/invoices/${id}`)
            .then(({ data }) => setInvoice(data))
            .catch(() => setInvoice(null))
            .finally(() => setIsLoading(false));
    }, [id]);

    if (isLoading) return <Loading />;
    else if (!!invoice) return <Invoice invoice={invoice} />;
    return <NotFound />;
};

export default InvoiceView;
