import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import InvoiceView from "./InvoiceView";

function App() {
    return (
        <div className="container mx-auto flex flex-col min-h-screen overflow-x-hidden">
            <div className="flex-1 flex flex-col">
                <Router>
                    <Routes>
                        <Route path="/:id" caseSensitive="false" element={<InvoiceView />} />
                    </Routes>
                </Router>
            </div>
            <a className="text-xs text-center text-gray-500 align-bottom mb-4 mt-10" href="https://morabaa.com" target="_blank">
                شركة المربع للحلول البرمجية | morabaa.com
            </a>
        </div>
    );
}

export default App;
