import React from "react";

const CurrencySigns = ["د.ع.", "$"];

const CurrencyFormatter = (value, currencyId) => {
    return (
        new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
        })
            .format(value)
            .replace("$", "") + `${CurrencySigns[currencyId - 1]}`
    );
};

const TableHeader = ({ title, className = "" }) => (
    <th className={`border-b-2 text-xs md:text-base border-gray-500 print:border-black text-right ${className || ""}`}>{title}</th>
);

const TableData = ({ data }) => (
    <td className="font-semibold text-xs md:text-base text-gray-700 print:text-black text-right" dir="tlr">
        {data}
    </td>
);

const Invoice = ({ invoice }) => {
    if (!invoice) return <></>;

    return (
        <div className="flex flex-col p-4 lg:pt-16 lg:px-0">
            <div className="flex flex-row justify-between">
                <div className="flex-1 grid grid-cols-1 lg:grid-cols-2">
                    <h1 className="text-lg">
                        قائمة <span className="font-semibold text-gray-500">#{invoice.number}</span>
                        <br />
                        <span className="font-semibold text-gray-500">{new Date(invoice.createdAt).toLocaleString()}</span>
                    </h1>
                    <h2 className="lg:text-lg font-semibold text-gray-500 text-right lg:text-left">
                        الإسم التجاري: {invoice?.merchant?.name}
                        <br />
                        {invoice?.merchant?.address && (
                            <>
                                العنوان: {invoice?.merchant?.address}
                                <br />
                            </>
                        )}
                        {invoice?.merchant?.phone && (
                            <>
                                رقم الهاتف: {invoice?.merchant?.phone}
                                <br />
                            </>
                        )}
                        {invoice?.merchant?.taxId && (
                            <>
                                الرقم الضريبي: {invoice?.merchant?.taxId}
                                <br />
                            </>
                        )}
                    </h2>
                    <h2 className="lg:text-lg mt-4 lg:mt-0">
                        السيد/ة: <span className="font-semibold text-gray-500">{invoice.customer.name}</span>
                    </h2>
                </div>
            </div>
            <div className="flex-1 mt-8">
                <div className="p-2">
                    <table className="w-full">
                        <thead>
                            <tr className="text-gray-500 print:text-black">
                                <TableHeader title="ت" />
                                <TableHeader title="المادة" className="w-1/3" />
                                <TableHeader title="العدد" className="w-1/4" />
                                <TableHeader title="السعر" />
                                <TableHeader title="الإجمالي" />
                            </tr>
                        </thead>
                        <tbody className="text-gray-800 mt-4">
                            {invoice.items.map((item, index) => (
                                <tr key={item.id} className={`${index === invoice.items.length - 1 ? "" : "border-b"} border-gray-500 print:border-black`}>
                                    <TableData data={index + 1} />
                                    <TableData data={item.name} />
                                    <TableData data={`${item.quantity} ${item.packaging}`} />
                                    <TableData data={CurrencyFormatter(item.soldAt, item.currencyId)} />
                                    <TableData data={CurrencyFormatter(item.total, item.currencyId)} />
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <p className="mt-4">
                    <span className="font-semibold text-gray-700 print:text-black">إجمالي القائمة: </span>
                    {Object.keys(invoice.totalByCurrency).map((total, index) => (
                        <span key={index} className="text-gray-700 print:text-black">
                            {CurrencyFormatter(invoice.totalByCurrency[total], total)}
                        </span>
                    ))}
                    {invoice.vat > 0 && (
                        <>
                            <br />
                            <span className="font-semibold text-gray-700 print:text-black">الضريبة المضافة: </span>
                            <span className="text-gray-700 print:text-black">{CurrencyFormatter(invoice.vat, invoice.items[0].currencyId)}</span>
                        </>
                    )}
                </p>
            </div>
        </div>
    );
};

export default Invoice;
